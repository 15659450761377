function FAQButton () {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 154.652 52.348"
        preserveAspectRatio="xMidYMid meet"
        className="svgbuttons"
        id="faqbutton"
      >
        <g>
	<path d="M0,51.469V0.703h43.523V15.82h-27v5.836h17.438v8.859H16.523v20.953H0z"/>
	<path d="M41.766,51.469L58.465,0.703h25.207l16.98,50.766H81.457l-3.199-9.141H63.176l-3.199,9.141H41.766z
		 M71.121,11.953l-7.277,20.109h13.992L71.121,11.953z"/>
	<path d="M155.461,36.352v15.117l-30.199,0.879c-4.336-0.118-8.162-0.738-11.479-1.863
		c-3.317-1.125-6.147-2.807-8.49-5.045c-2.344-2.238-4.113-4.975-5.309-8.209s-1.793-6.938-1.793-11.109
		c0-4.266,0.621-7.928,1.863-10.986c1.242-3.059,3.059-5.713,5.449-7.963s5.273-3.938,8.648-5.063S121.839,0.281,127.09,0
		c5.344,0.281,10.055,1.172,14.133,2.672c4.078,1.5,7.406,3.985,9.984,7.453c2.578,3.469,3.727,8.625,3.445,15.469
		c-0.563,3.094-1.67,5.789-3.322,8.086c-1.652,2.297-0.768,3.188,2.654,2.672H155.461z M116.367,26.789
		c0,3.258,0.732,6.094,2.197,8.508c1.464,2.414,4.354,3.621,8.666,3.621v-2.496c6.469,0.656,9.949-0.205,10.441-2.584
		c0.492-2.378,0.809-4.729,0.949-7.049c-0.141-3.211-0.926-6.029-2.355-8.455c-1.43-2.426-4.442-3.639-9.035-3.639
		C119.988,14.695,116.367,18.727,116.367,26.789z"/>
</g>
      </svg>
    )
}

export default FAQButton;