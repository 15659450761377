function AboutButton () {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 271.758 52.066"
        preserveAspectRatio="xMidYMid meet"
        className="svgbuttons"
        id="aboutbutton"
      >
        <g>
	<path d="M0,51.152L16.699,0.387h25.207l16.98,50.766H39.691l-3.199-9.141H21.41l-3.199,9.141H0z M29.355,11.637
		l-7.277,20.109H36.07L29.355,11.637z"/>
	<path d="M58.184,51.152V0.387h34.629c5.555,0.047,9.779,1.008,12.674,2.883c2.895,1.875,4.646,5.016,5.256,9.422
		c0.211,3.375-0.504,6.024-2.145,7.945c-1.641,1.922-4.348,3.504-8.121,4.746v0.141c1.851,0.234,3.563,0.733,5.133,1.494
		c1.57,0.762,2.888,1.665,3.955,2.707c1.066,1.043,1.893,2.221,2.479,3.533c0.585,1.313,0.879,2.602,0.879,3.867
		c0,4.008-1.025,7.043-3.076,9.105c-2.051,2.063-4.688,3.41-7.91,4.043c-3.223,0.633-7.131,0.926-11.725,0.879H58.184z
		 M75.691,12.691v7.699h12.305c0.68,0,1.394-0.357,2.145-1.072c0.75-0.715,1.148-1.67,1.195-2.865
		c-0.094-1.383-0.545-2.355-1.354-2.918s-1.482-0.844-2.021-0.844H75.691z M75.691,31.5v9.387h11.285
		c1.945,0.07,3.644-0.352,5.098-1.266c1.453-0.914,2.18-2.039,2.18-3.375c0.141-1.477-0.452-2.637-1.775-3.48
		c-1.324-0.844-3.159-1.266-5.502-1.266H75.691z"/>
	<path d="M171.949,26.051c0,5.555-1.16,10.289-3.48,14.203c-2.32,3.915-5.678,6.855-10.072,8.824
		s-9.639,2.953-15.732,2.953c-6.094,0-11.326-0.99-15.697-2.971c-4.372-1.98-7.682-4.91-9.932-8.789
		c-2.25-3.879-3.375-8.619-3.375-14.221c0-4.242,0.645-7.975,1.934-11.197c1.289-3.222,3.176-5.936,5.66-8.139
		c2.484-2.203,5.531-3.873,9.141-5.01C134.003,0.568,138.094,0,142.664,0c6.07,0,11.308,1.002,15.715,3.006
		c4.406,2.004,7.77,4.957,10.09,8.859S171.949,20.496,171.949,26.051z M131.695,26.191c0,3.258,0.732,6.094,2.197,8.508
		c1.464,2.414,4.354,3.621,8.666,3.621c3.328,0,5.818-0.633,7.471-1.898s2.684-2.712,3.094-4.342
		c0.41-1.629,0.686-3.591,0.826-5.889c-0.141-3.257-0.926-6.1-2.355-8.525c-1.43-2.426-4.442-3.639-9.035-3.639
		C135.316,14.027,131.695,18.083,131.695,26.191z"/>
	<path d="M189.141,0.387V30.41c-0.141,3.422,0.515,5.801,1.969,7.137c1.453,1.336,3.727,2.051,6.82,2.145
		c2.484-0.047,4.558-0.773,6.223-2.18c1.664-1.406,2.449-3.773,2.355-7.102V0.387h16.523v29.777
		c-0.07,4.219-0.768,7.588-2.092,10.107c-1.324,2.52-3,4.623-5.027,6.311c-2.027,1.688-4.57,3-7.629,3.938
		s-6.463,1.453-10.213,1.547c-3.117-0.07-6.288-0.498-9.51-1.283c-3.223-0.785-6.035-2.021-8.438-3.709
		c-2.403-1.688-4.254-3.855-5.555-6.504c-1.301-2.648-1.951-6.105-1.951-10.371V0.387H189.141z"/>
	<path d="M271.758,0.387v15.117h-16.102v35.648h-16.523V15.504h-16.102V0.387H271.758z"/>
</g>
      </svg>
    )
}

export default AboutButton;