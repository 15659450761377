function PhoneNumber () {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 83.912 8.607"
        preserveAspectRatio="xMidYMid meet"
        className="footerbuttons"
        id="phonenumber"
      >
        <g>
	<path d="M0,4.236c0-0.633,0.091-1.216,0.272-1.749s0.449-0.998,0.803-1.395s0.79-0.701,1.31-0.914
		s1.117-0.319,1.793-0.319c0.637,0,1.212,0.102,1.726,0.305s0.951,0.499,1.313,0.888S7.853,1.903,8.042,2.44
		s0.284,1.136,0.284,1.796c0,0.879-0.158,1.647-0.475,2.306S7.063,7.709,6.437,8.068s-1.38,0.539-2.259,0.539
		c-0.875,0-1.628-0.183-2.259-0.548S0.811,7.18,0.486,6.516S0,5.092,0,4.236z M3.146,4.236c0,0.422,0.008,0.766,0.023,1.031
		s0.054,0.508,0.114,0.727s0.164,0.395,0.311,0.527s0.341,0.199,0.583,0.199c0.309,0,0.537-0.11,0.686-0.331s0.239-0.494,0.272-0.82
		s0.05-0.771,0.05-1.333c0-0.586-0.017-1.043-0.05-1.371s-0.123-0.598-0.27-0.809S4.49,1.74,4.178,1.74
		c-0.32,0-0.556,0.106-0.706,0.319S3.229,2.542,3.196,2.868S3.146,3.65,3.146,4.236z"/>
	<path d="M8.754,0h6.814v2.326l-3.012,6.135H8.953l3.568-6.135H8.754V0z"/>
	<path d="M16.67,8.379V6.422c0.219,0.059,0.422,0.109,0.609,0.152s0.406,0.078,0.656,0.105s0.523,0.041,0.82,0.041
		c0.539,0,0.998-0.133,1.377-0.398s0.568-0.697,0.568-1.295c-0.43,0.344-1.059,0.516-1.887,0.516c-0.504,0-0.979-0.104-1.424-0.313
		s-0.805-0.521-1.078-0.935s-0.41-0.902-0.41-1.465c0-0.5,0.103-0.938,0.308-1.315s0.483-0.688,0.835-0.932s0.746-0.426,1.184-0.545
		s0.898-0.179,1.383-0.179c0.605,0,1.162,0.101,1.67,0.302s0.943,0.488,1.307,0.861s0.644,0.818,0.841,1.336
		s0.296,1.079,0.296,1.685c0,0.969-0.191,1.798-0.574,2.487s-0.913,1.208-1.591,1.556s-1.456,0.521-2.335,0.521
		C18.252,8.607,17.4,8.531,16.67,8.379z M18.662,2.83c0,0.332,0.094,0.6,0.281,0.803s0.438,0.305,0.75,0.305
		c0.332,0,0.583-0.104,0.753-0.311s0.255-0.496,0.255-0.867c0-0.316-0.086-0.577-0.258-0.782s-0.422-0.308-0.75-0.308
		c-0.316,0-0.567,0.108-0.753,0.325S18.662,2.49,18.662,2.83z"/>
	<path d="M27.352,6.422h3.428v2.039h-6.621V6.082c0.922-0.461,1.738-0.92,2.449-1.377
		c0.355-0.246,0.636-0.488,0.841-0.727s0.308-0.516,0.308-0.832c0-0.406-0.15-0.704-0.451-0.894s-0.67-0.284-1.107-0.284
		c-0.691,0-1.314,0.119-1.869,0.357V0.123c0.445-0.082,0.887-0.146,1.324-0.193s0.879-0.07,1.324-0.07
		c1.074,0,1.977,0.211,2.707,0.633s1.096,1.135,1.096,2.139c0,0.457-0.103,0.877-0.308,1.26s-0.47,0.722-0.794,1.017
		s-0.658,0.548-1.002,0.759s-0.785,0.455-1.324,0.732V6.422z"/>
	<path d="M31.98,8.379V6.422c0.219,0.059,0.422,0.109,0.609,0.152s0.406,0.078,0.656,0.105s0.523,0.041,0.82,0.041
		c0.539,0,0.998-0.133,1.377-0.398s0.568-0.697,0.568-1.295c-0.43,0.344-1.059,0.516-1.887,0.516c-0.504,0-0.979-0.104-1.424-0.313
		s-0.805-0.521-1.078-0.935s-0.41-0.902-0.41-1.465c0-0.5,0.103-0.938,0.308-1.315s0.483-0.688,0.835-0.932s0.746-0.426,1.184-0.545
		s0.898-0.179,1.383-0.179c0.605,0,1.162,0.101,1.67,0.302s0.943,0.488,1.307,0.861s0.644,0.818,0.841,1.336
		s0.296,1.079,0.296,1.685c0,0.969-0.191,1.798-0.574,2.487s-0.913,1.208-1.591,1.556s-1.456,0.521-2.335,0.521
		C33.563,8.607,32.711,8.531,31.98,8.379z M33.973,2.83c0,0.332,0.094,0.6,0.281,0.803s0.438,0.305,0.75,0.305
		c0.332,0,0.583-0.104,0.753-0.311s0.255-0.496,0.255-0.867c0-0.316-0.086-0.577-0.258-0.782s-0.422-0.308-0.75-0.308
		c-0.316,0-0.567,0.108-0.753,0.325S33.973,2.49,33.973,2.83z"/>
	<path d="M46.477,0.082v1.957c-0.098-0.023-0.239-0.059-0.425-0.105s-0.348-0.082-0.486-0.105
		s-0.311-0.044-0.516-0.062S44.625,1.74,44.391,1.74c-0.547,0-1.008,0.133-1.383,0.398s-0.563,0.697-0.563,1.295
		c0.199-0.164,0.454-0.291,0.765-0.381s0.683-0.135,1.116-0.135c0.508,0,0.985,0.106,1.433,0.319s0.807,0.524,1.078,0.935
		s0.407,0.896,0.407,1.459c0,0.66-0.175,1.214-0.524,1.661s-0.807,0.778-1.371,0.993s-1.169,0.322-1.813,0.322
		c-0.801,0-1.516-0.178-2.145-0.533s-1.114-0.852-1.456-1.488s-0.513-1.359-0.513-2.168c0-0.969,0.191-1.797,0.574-2.484
		s0.914-1.205,1.594-1.553s1.457-0.521,2.332-0.521C44.914-0.141,45.766-0.066,46.477,0.082z M44.484,5.631
		c0-0.336-0.092-0.604-0.275-0.806s-0.436-0.302-0.756-0.302c-0.328,0-0.578,0.104-0.75,0.311s-0.258,0.496-0.258,0.867
		c0,0.313,0.089,0.572,0.267,0.779s0.425,0.311,0.741,0.311c0.313,0,0.563-0.109,0.75-0.328S44.484,5.967,44.484,5.631z"/>
	<path d="M48.744,5.139V3.287c0.305,0.016,0.529,0.023,0.674,0.023c0.273,0,0.52-0.012,0.738-0.035
		s0.418-0.088,0.598-0.193s0.27-0.268,0.27-0.486c0-0.309-0.119-0.528-0.357-0.659S50.139,1.74,49.799,1.74s-0.69,0.033-1.052,0.1
		s-0.687,0.145-0.976,0.234V0.182c0.371-0.117,0.781-0.2,1.23-0.249s0.959-0.073,1.529-0.073c0.438,0,0.865,0.043,1.283,0.129
		s0.807,0.222,1.166,0.407s0.647,0.433,0.864,0.741s0.325,0.674,0.325,1.096c0,0.961-0.537,1.598-1.611,1.91v0.023
		c1.152,0.301,1.729,0.953,1.729,1.957c0,0.578-0.179,1.053-0.536,1.424s-0.815,0.641-1.374,0.809s-1.152,0.252-1.781,0.252
		c-0.48,0-0.991-0.032-1.532-0.097s-0.999-0.149-1.374-0.255V6.287c0.273,0.094,0.604,0.176,0.993,0.246s0.761,0.105,1.116,0.105
		c0.426,0,0.757-0.054,0.993-0.161s0.354-0.306,0.354-0.595c0-0.32-0.138-0.539-0.413-0.656s-0.595-0.176-0.958-0.176
		C49.529,5.051,49.186,5.08,48.744,5.139z"/>
	<path d="M57.973,6.422H61.4v2.039h-6.621V6.082c0.922-0.461,1.738-0.92,2.449-1.377
		c0.355-0.246,0.636-0.488,0.841-0.727s0.308-0.516,0.308-0.832c0-0.406-0.15-0.704-0.451-0.894s-0.67-0.284-1.107-0.284
		c-0.691,0-1.314,0.119-1.869,0.357V0.123c0.445-0.082,0.887-0.146,1.324-0.193s0.879-0.07,1.324-0.07
		c1.074,0,1.977,0.211,2.707,0.633S61.4,1.627,61.4,2.631c0,0.457-0.103,0.877-0.308,1.26s-0.47,0.722-0.794,1.017
		s-0.658,0.548-1.002,0.759s-0.785,0.455-1.324,0.732V6.422z"/>
	<path d="M65.074,6.422h3.428v2.039h-6.621V6.082c0.922-0.461,1.738-0.92,2.449-1.377
		c0.355-0.246,0.636-0.488,0.841-0.727s0.308-0.516,0.308-0.832c0-0.406-0.15-0.704-0.451-0.894s-0.67-0.284-1.107-0.284
		c-0.691,0-1.314,0.119-1.869,0.357V0.123c0.445-0.082,0.887-0.146,1.324-0.193s0.879-0.07,1.324-0.07
		c1.074,0,1.977,0.211,2.707,0.633s1.096,1.135,1.096,2.139c0,0.457-0.103,0.877-0.308,1.26s-0.47,0.722-0.794,1.017
		s-0.658,0.548-1.002,0.759s-0.785,0.455-1.324,0.732V6.422z"/>
	<path d="M68.889,6.205c0-1.102,0.559-1.785,1.676-2.051V4.131c-0.348-0.117-0.634-0.257-0.858-0.419
		s-0.394-0.37-0.507-0.624s-0.17-0.559-0.17-0.914c0-0.438,0.108-0.806,0.325-1.104s0.51-0.537,0.879-0.715s0.774-0.305,1.216-0.381
		s0.902-0.114,1.383-0.114c0.672,0,1.29,0.071,1.854,0.214s1.024,0.387,1.38,0.732S76.6,1.607,76.6,2.174
		c0,0.52-0.128,0.932-0.384,1.236s-0.632,0.549-1.128,0.732V4.131v0.023c0.516,0.129,0.931,0.364,1.245,0.706
		s0.472,0.79,0.472,1.345c0,0.445-0.116,0.824-0.349,1.137s-0.538,0.561-0.917,0.744s-0.805,0.316-1.277,0.398
		s-0.949,0.123-1.43,0.123c-0.652,0-1.277-0.076-1.875-0.229s-1.092-0.406-1.482-0.762S68.889,6.791,68.889,6.205z M73.781,5.725
		c0-0.32-0.078-0.58-0.234-0.779s-0.395-0.299-0.715-0.299s-0.554,0.101-0.7,0.302s-0.22,0.468-0.22,0.8
		c0,0.328,0.074,0.597,0.223,0.806s0.381,0.313,0.697,0.313c0.32,0,0.559-0.106,0.715-0.319S73.781,6.061,73.781,5.725z
		 M71.936,2.391c0,0.293,0.079,0.531,0.237,0.715s0.378,0.277,0.659,0.281c0.27,0,0.481-0.093,0.636-0.278s0.231-0.417,0.231-0.694
		c0-0.27-0.077-0.497-0.231-0.683s-0.366-0.278-0.636-0.278c-0.277,0-0.496,0.091-0.656,0.272S71.936,2.129,71.936,2.391z"/>
	<path d="M77.098,0h6.814v2.326L80.9,8.461h-3.604l3.568-6.135h-3.768V0z"/>
</g>
      </svg>
    )
}

export default PhoneNumber;