function EmailAddress () {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-18.635 0 181.202 8.73"
        preserveAspectRatio="xMidYMid meet"
        className="footerbuttons"
        id="emailaddress"
      >
        <g>
	<path d="M73.702,4.313
		c0,0.452-0.065,0.922-0.196,1.409c-0.131,0.486-0.29,0.882-0.478,1.186h-2.417l-0.074-0.386c-0.086,0.059-0.171,0.116-0.255,0.168
		c-0.084,0.053-0.178,0.098-0.28,0.134c-0.115,0.043-0.223,0.074-0.321,0.094c-0.099,0.021-0.235,0.03-0.406,0.03
		c-0.251,0-0.485-0.056-0.703-0.168c-0.218-0.112-0.406-0.274-0.565-0.485c-0.155-0.205-0.278-0.466-0.369-0.784
		c-0.091-0.319-0.136-0.665-0.136-1.038c0-0.384,0.056-0.731,0.168-1.045c0.112-0.313,0.262-0.584,0.45-0.813
		c0.179-0.208,0.389-0.373,0.632-0.496c0.243-0.122,0.496-0.183,0.76-0.183c0.211,0,0.399,0.043,0.564,0.129
		c0.166,0.086,0.292,0.173,0.382,0.262V2.025l1.094-0.005v4.001h0.743c0.096-0.218,0.174-0.477,0.235-0.772
		c0.061-0.296,0.091-0.599,0.091-0.905c0-0.542-0.072-1.016-0.218-1.422c-0.146-0.405-0.349-0.745-0.609-1.02
		c-0.264-0.277-0.574-0.483-0.929-0.616c-0.355-0.134-0.738-0.201-1.151-0.201c-0.39,0-0.758,0.074-1.106,0.224
		c-0.349,0.147-0.669,0.37-0.963,0.664c-0.281,0.283-0.506,0.634-0.676,1.05c-0.17,0.416-0.255,0.876-0.255,1.382
		c0,0.538,0.08,1.017,0.24,1.436c0.16,0.419,0.38,0.771,0.661,1.056c0.271,0.281,0.586,0.493,0.946,0.636
		c0.36,0.144,0.736,0.216,1.129,0.216c0.277,0,0.579-0.024,0.904-0.074c0.325-0.05,0.625-0.115,0.898-0.197V8.53
		c-0.3,0.069-0.59,0.121-0.869,0.153s-0.592,0.05-0.938,0.05c-0.584,0-1.127-0.104-1.627-0.314s-0.93-0.505-1.29-0.884
		c-0.363-0.383-0.646-0.842-0.849-1.377c-0.203-0.534-0.305-1.123-0.305-1.763c0-0.607,0.105-1.173,0.317-1.698
		c0.211-0.525,0.502-0.984,0.872-1.376c0.367-0.386,0.801-0.689,1.303-0.909c0.501-0.22,1.036-0.329,1.604-0.329
		c0.564,0,1.091,0.103,1.58,0.308c0.488,0.205,0.909,0.491,1.263,0.861c0.359,0.369,0.641,0.813,0.844,1.329
		C73.6,3.094,73.702,3.673,73.702,4.313z M70.433,5.596V3.1c-0.135-0.069-0.26-0.12-0.374-0.151
		c-0.114-0.032-0.243-0.047-0.389-0.047c-0.32,0-0.566,0.142-0.738,0.426c-0.171,0.284-0.257,0.66-0.257,1.129
		c0,0.52,0.072,0.904,0.215,1.156c0.144,0.253,0.379,0.379,0.706,0.379c0.172,0,0.321-0.034,0.448-0.102S70.301,5.725,70.433,5.596z
		"/>
</g>
<g>
	<path d="M-11.627,2.613h-2.063c-1.004-0.031-1.152,0.246-0.445,0.832c1.266,1.109,1.887,2.188,1.863,3.234
		s-0.645,1.672-1.863,1.875h-4.5v-2.52h1.781c1.105,0.031,1.246-0.277,0.422-0.926c-1.359-1.25-1.938-2.398-1.734-3.445
		s0.844-1.57,1.922-1.57h4.617V2.613z"/>
	<path d="M-3.922,0.094v2.52h-2.684v5.941H-9.36V2.613h-2.684v-2.52H-3.922z"/>
	<path d="M-4.45,8.554l2.783-8.461h4.201l2.83,8.461H2.166L1.632,7.031h-2.514l-0.533,1.523H-4.45z M0.443,1.969
		L-0.77,5.32h2.332L0.443,1.969z"/>
	<path d="M5.248,8.554V0.094h5.508c0.68,0.063,1.238,0.242,1.676,0.539s0.758,0.695,0.961,1.195
		c0.156,0.75,0.164,1.328,0.023,1.734s-0.387,0.746-0.738,1.02s-0.855,0.488-1.512,0.645l0.633,0.809h2.531v2.52h-3.34L8.306,5.76
		v2.795H5.248z M8.306,2.203v1.559h2.133c0.113,0,0.236-0.042,0.369-0.126S11.011,3.34,11.019,3C11,2.676,10.92,2.462,10.782,2.358
		s-0.255-0.155-0.349-0.155H8.306z"/>
	<path d="M20.025,6.035h2.648v2.52h-3.75c-0.918,0-1.748-0.163-2.49-0.489s-1.327-0.815-1.755-1.468
		s-0.642-1.451-0.642-2.396c0-0.664,0.128-1.257,0.384-1.778s0.61-0.955,1.063-1.301s0.974-0.604,1.562-0.773
		s1.214-0.255,1.878-0.255h2.32h1.43v2.52h-2.648c-0.594,0-1.033,0.018-1.318,0.053s-0.539,0.094-0.762,0.176
		s-0.42,0.225-0.592,0.428s-0.271,0.563-0.299,1.078c0.02,0.5,0.119,0.857,0.299,1.072s0.398,0.357,0.656,0.428
		s0.523,0.119,0.797,0.146S19.486,6.035,20.025,6.035z"/>
	<path d="M27.876,3.011V0.094h2.754v8.461h-2.754V5.508h-2.215v3.047h-2.754V0.094h2.754v2.918H27.876z"/>
	<path d="M40.638,4.371c0,0.926-0.193,1.715-0.58,2.367s-0.946,1.143-1.679,1.471s-1.606,0.492-2.622,0.492
		s-1.888-0.165-2.616-0.495s-1.28-0.818-1.655-1.465s-0.563-1.437-0.563-2.37c0-0.707,0.107-1.329,0.322-1.866
		s0.529-0.989,0.943-1.356s0.922-0.646,1.523-0.835s1.283-0.284,2.045-0.284c1.012,0,1.885,0.167,2.619,0.501
		s1.295,0.826,1.682,1.477S40.638,3.445,40.638,4.371z M33.929,4.394c0,0.543,0.122,1.016,0.366,1.418s0.726,0.604,1.444,0.604
		c0.555,0,0.97-0.105,1.245-0.316s0.447-0.452,0.516-0.724s0.114-0.599,0.138-0.981c-0.023-0.543-0.154-1.017-0.393-1.421
		s-0.74-0.606-1.506-0.606C34.533,2.367,33.929,3.043,33.929,4.394z"/>
	<path d="M41.042,8.554V0.094h5.508c0.68,0.063,1.238,0.242,1.676,0.539s0.758,0.695,0.961,1.195
		c0.156,0.75,0.164,1.328,0.023,1.734s-0.387,0.746-0.738,1.02S47.617,5.07,46.96,5.226l0.633,0.809h2.531v2.52h-3.34L44.101,5.76
		v2.795H41.042z M44.101,2.203v1.559h2.133c0.113,0,0.236-0.042,0.369-0.126S46.806,3.34,46.814,3
		c-0.02-0.324-0.099-0.538-0.237-0.642s-0.255-0.155-0.349-0.155H44.101z"/>
	<path d="M50.125,8.554V0.094h7.254v2.52h-4.5v0.973h2.906v1.477h-2.906v0.973h5.215v2.52H50.125z"/>
	<path d="M64.75,2.613h-2.063c-1.004-0.031-1.152,0.246-0.445,0.832c1.266,1.109,1.887,2.188,1.863,3.234
		s-0.645,1.672-1.863,1.875h-4.5v-2.52h1.781c1.105,0.031,1.246-0.277,0.422-0.926c-1.359-1.25-1.938-2.398-1.734-3.445
		s0.844-1.57,1.922-1.57h4.617V2.613z"/>
</g>
<g>
	<path d="M79.896,2.971V0.053h2.754v8.46h-2.754V5.467h-2.215v3.047h-2.754v-8.46h2.754v2.918H79.896z"/>
	<path d="M92.658,4.33c0,0.926-0.193,1.715-0.58,2.367s-0.947,1.143-1.68,1.471S88.791,8.66,87.776,8.66
		c-1.016,0-1.888-0.164-2.616-0.494s-1.28-0.818-1.655-1.465s-0.563-1.438-0.563-2.371c0-0.707,0.107-1.329,0.322-1.866
		s0.529-0.989,0.943-1.356s0.922-0.646,1.523-0.835s1.283-0.284,2.045-0.284c1.011,0,1.884,0.167,2.62,0.501
		c0.732,0.334,1.295,0.826,1.682,1.477S92.658,3.405,92.658,4.33z M85.948,4.353c0,0.543,0.122,1.016,0.366,1.418
		s0.726,0.604,1.444,0.604c0.554,0,0.97-0.105,1.245-0.316s0.447-0.451,0.516-0.723s0.115-0.6,0.139-0.982
		c-0.023-0.542-0.156-1.016-0.393-1.42c-0.24-0.404-0.742-0.606-1.507-0.606C86.552,2.326,85.948,3.002,85.948,4.353z"/>
	<path d="M100.773,0.053v2.52H98.09v5.941h-2.754V2.573h-2.684v-2.52H100.773z"/>
	<path d="M100.949,8.513v-8.46h2.754l1.852,3.516l1.84-3.516h2.754v8.46h-2.754V4.998l-1.77,3.516h-0.152
		l-1.77-3.516v3.516H100.949z"/>
	<path d="M109.914,8.513l2.783-8.46h4.201l2.83,8.46h-3.199l-0.533-1.523h-2.514l-0.533,1.523H109.914z
		 M114.807,1.928l-1.213,3.351h2.332L114.807,1.928z"/>
	<path d="M119.494,0.053h2.754v8.46h-2.754V0.053z"/>
	<path d="M122.834,8.513v-8.46h2.754v5.941h4.582v2.52H122.834z"/>
	<path d="M131.084,8.513V6.187h2.613v2.326H131.084z"/>
	<path d="M140.658,5.994h2.648v2.52h-3.75c-0.918,0-1.748-0.162-2.49-0.488s-1.328-0.816-1.756-1.469
		s-0.641-1.451-0.641-2.396c0-0.664,0.127-1.256,0.383-1.778s0.611-0.955,1.064-1.301s0.973-0.604,1.561-0.773
		s1.215-0.255,1.879-0.255h2.32h1.43v2.52h-2.648c-0.594,0-1.033,0.018-1.318,0.053s-0.539,0.094-0.762,0.176
		s-0.42,0.225-0.592,0.428s-0.271,0.563-0.299,1.078c0.02,0.5,0.119,0.857,0.299,1.072s0.398,0.357,0.656,0.428
		s0.523,0.119,0.797,0.146S140.119,5.994,140.658,5.994z"/>
	<path d="M153.08,4.33c0,0.926-0.193,1.715-0.58,2.367s-0.947,1.143-1.68,1.471s-1.605,0.492-2.621,0.492
		s-1.889-0.164-2.617-0.494s-1.279-0.818-1.654-1.465s-0.563-1.438-0.563-2.371c0-0.707,0.107-1.329,0.322-1.866
		s0.529-0.989,0.943-1.356s0.922-0.646,1.523-0.835s1.283-0.284,2.045-0.284c1.012,0,1.885,0.167,2.619,0.501
		s1.295,0.826,1.682,1.477S153.08,3.405,153.08,4.33z M146.371,4.353c0,0.543,0.121,1.016,0.365,1.418s0.727,0.604,1.445,0.604
		c0.555,0,0.969-0.105,1.244-0.316s0.447-0.451,0.516-0.723s0.115-0.6,0.139-0.982c-0.023-0.542-0.154-1.016-0.393-1.42
		s-0.74-0.606-1.506-0.606C146.975,2.326,146.371,3.002,146.371,4.353z"/>
	<path d="M153.367,8.513v-8.46h2.754l1.852,3.516l1.84-3.516h2.754v8.46h-2.754V4.998l-1.77,3.516h-0.152
		l-1.77-3.516v3.516H153.367z"/>
</g>
      </svg>
    )
}

export default EmailAddress;