function ServicesButton () {
    return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 360.879 50.766"
        preserveAspectRatio="xMidYMid meet"
        className="svgbuttons"
        id="servicesbutton"
      >
        <g>
	<path d="M42.047,15.117H29.672c-6.024-0.188-6.914,1.477-2.672,4.992c7.594,6.657,11.32,13.125,11.18,19.406
		c-0.141,6.281-3.867,10.032-11.18,11.25H0V35.648h10.688c6.632,0.188,7.476-1.664,2.531-5.555
		C5.063,22.594,1.594,15.703,2.813,9.422C4.031,3.141,7.875,0,14.344,0h27.703V15.117z"/>
	<path d="M42.012,50.766V0h43.523v15.117h-27v5.836h17.438v8.859H58.535v5.836h31.289v15.117H42.012z"/>
	<path d="M89.824,50.766V0h33.047c4.078,0.375,7.43,1.454,10.055,3.234c2.625,1.781,4.547,4.172,5.766,7.172
		c0.937,4.5,0.984,7.969,0.141,10.406c-0.844,2.438-2.32,4.477-4.43,6.117c-2.109,1.641-5.133,2.93-9.07,3.867l3.797,4.852h15.188
		v15.117h-20.039l-16.102-16.77v16.77H89.824z M108.176,12.656v9.352h12.797c0.68,0,1.418-0.251,2.215-0.756
		c0.796-0.504,1.218-1.775,1.266-3.814c-0.118-1.945-0.592-3.229-1.424-3.85c-0.832-0.621-1.529-0.932-2.092-0.932H108.176z"/>
	<path d="M158.766,0l11.531,38.988L181.969,0h20.355l-18.105,50.766h-26.965L139.395,0H158.766z"/>
	<path d="M200.918,0h16.523v50.766h-16.523V0z"/>
	<path d="M255.129,35.648h15.891v15.117h-22.5c-5.508,0-10.489-0.979-14.941-2.936
		c-4.453-1.957-7.963-4.892-10.529-8.807c-2.566-3.914-3.85-8.707-3.85-14.379c0-3.984,0.768-7.541,2.303-10.67
		c1.535-3.129,3.662-5.73,6.381-7.805c2.719-2.074,5.841-3.621,9.369-4.641C240.779,0.51,244.535,0,248.52,0h13.922h8.578v15.117
		h-15.891c-3.563,0-6.2,0.105-7.91,0.316c-1.711,0.211-3.234,0.563-4.57,1.055s-2.52,1.348-3.551,2.566
		c-1.032,1.219-1.629,3.375-1.793,6.469c0.117,3,0.715,5.145,1.793,6.434c1.078,1.289,2.391,2.145,3.938,2.566
		s3.141,0.715,4.781,0.879C249.457,35.566,251.895,35.648,255.129,35.648z"/>
	<path d="M273.129,50.766V0h43.523v15.117h-27v5.836h17.438v8.859h-17.438v5.836h31.289v15.117H273.129z"/>
	<path d="M360.879,15.117h-12.375c-6.023-0.188-6.914,1.477-2.672,4.992c7.594,6.657,11.32,13.125,11.18,19.406
		c-0.141,6.281-3.867,10.032-11.18,11.25h-27V35.648h10.688c6.633,0.188,7.477-1.664,2.531-5.555
		c-8.156-7.5-11.625-14.391-10.406-20.672C322.863,3.141,326.707,0,333.176,0h27.703V15.117z"/>
</g>
      </svg>
    )
}

export default ServicesButton;