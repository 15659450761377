import './FAQ.css';

function FAQ() {

  return (
    <div className="FAQ">
 
    </div>

  );
}

export default FAQ;